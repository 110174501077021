























































































import { Component, Prop, Vue } from 'vue-property-decorator'
@Component({
    components: {}
})
export default class LsWithdrawalDetails extends Vue {
    $refs!: {
        valueRef: any
    }
    @Prop() value: any
}














































































































































































import { Component, Prop, Vue } from 'vue-property-decorator'
import LsDialog from '@/components/ls-dialog.vue'
import LsWithdrawalDetails from '@/components/finance/ls-withdrawal-details.vue'
import LsPagination from '@/components/ls-pagination.vue'
import MaterialSelect from '@/components/material-select/index.vue'
// import PopoverInput from '@/components/popover-input.vue'
import {
    apiWithdrawSearch,
    apiWithdrawDetail,
    apiWithdrawPass,
    apiWithdrawRefuse,
    apiTransferSuccess,
    apiTransferFail
} from '@/api/finance/finance'
@Component({
    components: {
        LsDialog,
        LsPagination,
        LsWithdrawalDetails,
        MaterialSelect
        // PopoverInput,
    }
})
export default class GoodsPane extends Vue {
    $refs!: {
        valueRef: any
    }
    @Prop() value: any
    @Prop() pager!: any
    status = true
    selectIds: any[] = []
    withdrawDetail = {}
    // 是否审核通过
    isExamine = '' // 1-通过 2-不通过
    audit_remark = ''

    isTransfer = '' // 1-通过 2-不通过
    imgTransfer = '' // 转账凭证
    transfer_remark = ''

    // 查询结果
    onWithdrawSearch(id: any) {
        apiWithdrawSearch({
            id: id
        }).then(() => {
            this.$emit('refresh')
        })
    }

    // 详情
    onWithdrawDetail(id: any) {
        apiWithdrawDetail({
            id: id
        }).then((res: any) => {
            this.withdrawDetail = res
            // this.$emit('refresh')
        })
    }

    // 审核
    onWithdrawExamine(id: number) {
        let res =
            this.isExamine == '1'
                ? apiWithdrawPass({
                      id: id as number,
                      audit_remark: this.audit_remark
                  })
                : apiWithdrawRefuse({
                      id: id as number,
                      audit_remark: this.audit_remark
                  })
        res.then(() => {
            this.$emit('refresh')
        })

        this.isExamine = ''
        this.audit_remark = ''
    }

    onClear() {
        this.isExamine = ''
        this.audit_remark = ''
    }

    // 转账
    onTransfer(id: number) {
        // console.log('this.isTransfer')
        // console.log(this.isTransfer)
        let res =
            this.isTransfer == '1'
                ? apiTransferSuccess({
                      id: id as number,
                      transfer_remark: this.transfer_remark,
                      transfer_voucher: this.imgTransfer
                  })
                : apiTransferFail({
                      id: id as number,
                      transfer_remark: this.transfer_remark,
                      transfer_voucher: this.imgTransfer
                  })
        res.then(() => {
            this.$emit('refresh')
        })

        this.isTransfer = ''
        this.transfer_remark = ''
        this.imgTransfer = ''
    }
}
